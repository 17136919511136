import React from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import SEO from "../components/Layout/SEO";
import PageWrapper from "../components/PageWrapper";

const Contact = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "right",
          button: "account", // cta, account, null
        }}
      >
        <SEO
          title="Contact Camelo | Find Answers to Your Questions"
          description="Get in touch with the Camelo team to get set up and see if Camelo is a good fit for your business."
        ></SEO>
        <div className="inner-banner pt-29 pb-md-11">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col lg="9" xl="8">
                <div className="px-md-15 text-center">
                  <h2 className="title gr-text-2 mb-9">Contact us</h2>
                  <p className="gr-text-8 mb-13"></p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="pb-5 pb-md-33 ">
          <Container>
            <Row className="justify-content-center">
              <Col xs="12" className="mb-9">
                <Row>
                  <Col md="6" className="mb-13">
                    <div className="single-contact-widget d-flex">
                      <div className="widget-icon circle-sm-2 bg-white gr-text-6 text-primary mr-7">
                        <i className="icon icon-email-83"></i>
                      </div>
                      <div className="widget-text">
                        <h3 className="gr-text-6 mb-5">Email us</h3>
                        <p className="gr-text-7 mb-0">hello@camelohq.com</p>
                      </div>
                    </div>
                  </Col>
                  <Col md="6" className="mb-13">
                    <div className="single-contact-widget d-flex">
                      <div className="widget-icon circle-sm-2 bg-white gr-text-6 text-primary mr-7">
                        <i className="icon icon-pin-3-2"></i>
                      </div>
                      <div className="widget-text">
                        <h3 className="gr-text-6 mb-5">Office</h3>

                        <div className="text-primary">
                          <strong>United States</strong>
                        </div>
                        <p className="gr-text-9">
                          2055 Limestone Rd, STE 200-C
                          <br />
                          Wilmington, DE 19808, USA
                        </p>
                        {/* <p className="gr-text-8"> */}
                        {/*   🇺🇸 (302) 574-6556 */}
                        {/* </p> */}

                        <div className="text-primary">
                          <strong>Vietnam</strong>
                        </div>
                        <p className="gr-text-9 mb-0">
                          SV6-17.07, Sun Avenue, 28 Mai Chi Tho St.,
                          <br />
                          Thu Duc City, HCMC, VN
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs="12">
                <div className="login-form shadow-1 rounded-10 overflow-hidden">
                  <iframe
                    id="mailchimpForm"
                    width="100%"
                    height="1200px"
                    border="0"
                    frameBorder="0"
                    src="https://us2.list-manage.com/contact-form?u=1570388be5a3d99efb99e290e&form_id=9acd88ac24d60f0c1873f589449c14c5"
                    title="Contact Us Form"
                  ></iframe>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default Contact;
